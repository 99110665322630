import { Different } from '@/homePage/Different';
import { FirstVisit } from '@/homePage/FirstVisit';
import { Hero } from '@/homePage/Hero';
import { HomeFAQ } from '@/homePage/HomeFAQ';
import { Offer } from '@/homePage/Offer';
import { Partners } from '@/homePage/Partners';
import { Payments } from '@/homePage/Payments';
import { Specialty } from '@/homePage/Specialty';
import { Seo } from '@/layout/Seo';
import { Fragment } from 'react';

const Home: Page = () => (
    <Fragment>
        <Seo />
        <Hero />
        <FirstVisit />
        <Offer />
        <Specialty />
        <Different />
        <Partners />
        <HomeFAQ />
        <Payments />
    </Fragment>
);

export default Home;
