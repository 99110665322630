import { h1, px } from '@/layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const SpecialtySection = styled.section`
    ${px};
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: ${({ theme }) => theme.green};

    @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 64px;
        padding-bottom: 64px;
    }
`;

const Images = styled.div`
    margin: 0 auto;
    width: fit-content;

    > .gatsby-image-wrapper {
        max-width: 300px;
        display: block;

        :first-of-type {
            border-radius: 32px 0px;
        }

        :last-of-type {
            margin-top: 16px;
            border-radius: 0px 32px;
        }
    }

    @media (min-width: 501px) {
        order: 2;
        margin: 0;

        > .gatsby-image-wrapper {
            max-width: 319px;

            :last-of-type {
                margin-top: 32px;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        display: flex;

        > .gatsby-image-wrapper {
            :last-of-type {
                margin-top: 0;
                margin-left: 32px;
            }
        }
    }

    @media (min-width: 1024px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > .gatsby-image-wrapper {
            max-width: unset;
            display: inline-block;

            :last-of-type {
                margin-top: 32px;
                margin-left: ${({ theme }) => theme.spacing.xl};
            }
        }
    }
`;

const TextBox = styled.div`
    > * {
        color: ${({ theme }) => theme.white};
    }

    > h4 {
        ${h1};
        margin: 32px 0;
        text-transform: capitalize;
    }

    > p {
        margin: 0;
    }

    @media (min-width: 1024px) {
        max-width: 416px;
        margin-right: ${({ theme }) => theme.spacing.xl};

        > h4 {
            margin-top: 0;
        }
    }
`;

export const Specialty = () => {
    return (
        <SpecialtySection>
            <Images>
                <StaticImage
                    src="../../../assets/images/homepage/our-specialty-1.jpg"
                    alt="rehabilitation center"
                    width={320}
                    height={192}
                />

                <StaticImage
                    src="../../../assets/images/homepage/our-specialty-2.jpg"
                    alt="patient getting treatment"
                    width={490}
                    height={301}
                />
            </Images>
            <TextBox>
                <h4>our specialty</h4>
                <p>
                    Sports injury chiropractic specialize in diagnosing and treating sport injuries
                    & athletic performance related injuries. Our chiropractic care practice provides
                    injury prevention and rehabilitation care and are experienced in creating
                    treatment plans that allow athletes to return to their sport faster.
                    <br />
                    In addition to the spinal decompression and drop table mobilization our family
                    chiropractic care center also provides soft tissue mobilization, rehab, movement
                    retraining, and body mechanics instruction.
                </p>
            </TextBox>
        </SpecialtySection>
    );
};
