import { customScrollbar, h1, px } from '@/layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const PaymentsSection = styled.section`
    padding: 32px 0 0;
    background-color: ${({ theme }) => theme.offWhite};

    > h5 {
        ${h1};
        ${px};

        margin: 0 0 16px;
        text-transform: capitalize;
    }

    > p {
        ${px};
        margin: 0 auto;
    }

    @media (min-width: 1024px) {
        text-align: center;

        > p {
            padding: 0;
            max-width: 900px;
        }
    }

    @media (min-width: 1280px) {
        padding-top: 64px;
        padding-bottom: 64px;
        text-align: center;
    }
`;

const List = styled.div`
    ${customScrollbar};
    margin-top: 64px;
    padding-bottom: 32px;

    > .gatsby-image-wrapper {
        margin-right: 32px;
        flex-shrink: 0;

        :first-of-type {
            margin-left: 16px;
        }

        :last-of-type {
            margin-right: 16px;
        }
    }

    @media (min-width: 768px) {
        > .gatsby-image-wrapper:first-of-type {
            margin-left: 32px;
        }
    }

    @media (min-width: 1024px) {
        justify-content: space-between;
        padding: 0 32px 32px;

        > .gatsby-image-wrapper:first-of-type {
            margin-left: 0;
        }
    }

    @media (min-width: 1280px) {
        justify-content: center;
        padding: 0;

        > .gatsby-image-wrapper {
            margin-right: ${({ theme }) => theme.spacing.xxl};

            :last-of-type {
                margin-right: 0;
            }
        }
    }
`;

export const Payments = () => {
    return (
        <PaymentsSection id="insurances">
            <h5>Payments Accepted</h5>
            <p>
                Blue Cross Blue Shield, Health Partners, Cigna, Aetna, United Healthcare, Medica,
                Fulcrum Network, Preferred One, Independence Blue Cross, Medicare, Medicaid/state
                insurances, private payers including Auto accident and Workman’s compensation
                payers. 
            </p>
            <List>
                <StaticImage
                    src="../../../assets/images/homepage/payments/blue-cross.png"
                    alt="blue cross blue sheild"
                />
                <StaticImage
                    src="../../../assets/images/homepage/payments/health-partners.png"
                    alt="health partners"
                />
                <StaticImage src="../../../assets/images/homepage/payments/cigna.png" alt="cigna" />
                <StaticImage src="../../../assets/images/homepage/payments/aetna.png" alt="aetna" />
            </List>
        </PaymentsSection>
    );
};
