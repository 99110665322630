import { PrimaryLgBtn } from '@/Buttons/PrimaryLgBtn';
import facebook from '@a/fb.svg';
import waveDesktop from '@a/homepage/hero/wave-desktop.png';
import waveMob from '@a/homepage/hero/wave-mob.png';
import waveTab from '@a/homepage/hero/wave-tab.png';
import instagram from '@a/instagram.svg';
import location from '@a/location.svg';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';
import logo from '../../../assets/images/layout/nav/logo.png';

const HeroSection = styled.section`
    display: grid;
    position: relative;
    margin-bottom: 170px;

    > * {
        grid-area: 1/1;
    }

    > .gatsby-image-wrapper {
        height: 90vh;

        img {
            object-position: top center;
        }
    }

    > .hero-wave {
        z-index: 40;
        align-self: end;
        margin-bottom: -1px;
        display: none;
        width: 100%;

        &:first-of-type {
            display: block;
        }
    }

    @media (min-width: 501px) {
        ::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 17vh;
            background: linear-gradient(
                180deg,
                rgba(0, 36, 34, 0.4) 64.45%,
                rgba(0, 36, 34, 0) 125.53%,
                rgba(0, 36, 34, 0) 138.67%
            );
            filter: blur(128px);
            z-index: 10;
        }

        > .hero-wave {
            &:first-of-type {
                display: none;
            }

            &:nth-of-type(2) {
                display: block;
            }
        }
    }

    @media (min-width: 768px) {
        > .gatsby-image-wrapper {
            img {
                object-position: top left;

                @media (max-width: 1023px) and (min-height: 992px) {
                    object-position: top 0 left 12%;
                }
            }
        }
        > .hero-wave {
            &:nth-of-type(2) {
                display: none;
            }

            &:last-of-type {
                display: block;
            }
        }
    }

    @media (min-width: 1024px) {
        margin-bottom: 0;

        > .gatsby-image-wrapper {
            height: 100vh;

            img {
                object-position: top center;

                @media (max-width: 1440px) and (min-height: 992px) {
                    object-position: top 0 left 30%;
                }
            }
        }

        @media (max-height: 600px) {
            > .gatsby-image-wrapper {
                height: 737px;
            }
        }
    }
`;

const InfoBox = styled.div`
    padding: 24px 32px;
    background: ${({ theme }) => theme.darkGreenOverlay};
    backdrop-filter: blur(16px);
    border-radius: 64px;
    text-align: center;
    align-self: end;
    z-index: 60;
    margin: 0 -7px -160px;

    > img {
        margin: 0 auto;
    }

    > h1 {
        margin: 32px auto 16px;
        color: ${({ theme }) => theme.white};
    }

    > #hero-map-link {
        font-family: 'Raleway', sans-serif;
        font-weight: 600;
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: 0.1em;
        text-align: left;
        width: 82%;
        max-width: 303px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        color: ${({ theme }) => theme.white};
        transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out;

        > img {
            width: 32px;
            height: 32px;
            margin-right: 16px;
        }

        :hover,
        :focus {
            transform: translateY(-3px);
            opacity: 0.8;
        }
    }

    > #hero-primary-btn {
        margin: 16px auto 24px;
        width: max-content;
    }

    > div:last-of-type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 144px;
        margin: 0 auto;

        > a {
            &:focus,
            &:hover {
                > img {
                    filter: ${({ theme }) => theme.darkGreenFilter};
                }
            }
        }
    }

    @media (min-width: 501px) {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 1024px) {
        margin: -10vh 32px 0 0;
        align-self: center;
        justify-self: end;

        > h1 {
            margin-bottom: 32px;
        }

        > #hero-primary-btn {
            margin: 32px auto 40px;
        }
    }

    @media (min-width: 1280px) {
        width: 550px;

        > #hero-map-link {
            max-width: 383px;
            font-size: 1.125rem;

            > img {
                margin-right: 32px;
                width: 63px;
                height: 64px;
            }
        }
    }

    @media (min-width: 1366px) {
        margin-right: 5vw;
    }

    @media (min-width: 2000px) {
        margin-right: calc((100vw - 1700px) / 2);
    }
`;

export const Hero = () => {
    const data = useStaticQuery(graphql`
        query heroImages {
            mobile: file(relativePath: { eq: "homepage/hero/mob.jpg" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
            }
            desktop: file(relativePath: { eq: "homepage/hero/desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
            }
        }
    `);

    const heroImages = withArtDirection(getImage(data.desktop) as IGatsbyImageData, [
        {
            media: '(max-width: 767px)',
            image: getImage(data.mobile) as IGatsbyImageData,
        },
    ]);

    return (
        <HeroSection>
            <GatsbyImage image={heroImages} alt="doctors" loading="eager" />
            <img src={waveMob} alt="" className="hero-wave" />
            <img src={waveTab} alt="" className="hero-wave" />
            <img src={waveDesktop} alt="" className="hero-wave" />
            <InfoBox>
                <img src={logo} alt="integrate institute" width={275} height={55} loading="eager" />
                <h1>Move freely again</h1>
                <a href="#map" id="hero-map-link">
                    <img src={location} alt="location" width={63} height={64} />
                    1103 West Burnsville Pkwy Suite 120 Burnsville, MN 55337
                </a>
                <PrimaryLgBtn as="a" href="#first-visit" id="hero-primary-btn">
                    Your First Visit
                </PrimaryLgBtn>
                <div>
                    <a href="https://www.facebook.com/IntegrateInstitute/">
                        <img src={facebook} alt="facebook" width={32} height={32} />
                    </a>
                    <a href="https://www.instagram.com/integrate_institute/">
                        <img src={instagram} alt="instagram" width={30} height={30} />
                    </a>
                </div>
            </InfoBox>
        </HeroSection>
    );
};
