import { customScrollbar2, h1, px } from '@/layout/GlobalStyles';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

const PartnersSection = styled.section`
    padding: 64px 0;
    background-color: ${({ theme }) => theme.darkGreen};

    > h5 {
        ${h1};
        ${px};
        color: ${({ theme }) => theme.white};
        margin: 0 0 64px;
        text-transform: capitalize;
    }

    @media (min-width: 1280px) {
        > h5 {
            width: max-content;
            margin: 0 auto 64px;
        }
    }
`;

const PartnersList = styled.div`
    ${customScrollbar2};

    @media (min-width: 1280px) {
        justify-content: space-evenly;
    }
`;

const Card = styled.div`
    width: 200px;
    flex-shrink: 0;

    margin-right: 32px;

    :first-of-type {
        margin-left: 16px;
    }

    :last-of-type {
        margin-right: 16px;
    }

    > h6 {
        font-family: 'Raleway', sans-serif;
        font-size: 1.5rem;
        line-height: 162%;
        font-weight: 700;
        color: ${({ theme }) => theme.white};
        margin: 32px 0 16px;
    }

    > p {
        margin: 0;
        color: ${({ theme }) => theme.white};
    }

    @media (min-width: 768px) {
        margin-right: 64px;

        :first-of-type {
            margin-left: 32px;
        }
    }

    @media (min-width: 1024px) {
        margin-right: 96px;
    }

    @media (min-width: 1280px) {
        margin: 0;

        :first-of-type {
            margin: 0;
        }

        :last-of-type {
            margin: 0;
        }
    }
`;

export const Partners = () => {
    const {
        allFile: { nodes },
    } = useStaticQuery(graphql`
        query image {
            allFile(filter: { relativeDirectory: { eq: "homepage/partners" } } sort: { order: ASC, fields: name }) {
                nodes {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    `);

    const partners = [
        {
            image: getImage(nodes[0]) as IGatsbyImageData,
            title: 'Lakeville South High School',
            description:
                'Integrate Institute practitioners are the Directors of Sport Performance for LSHS!',
        },
        {
            image: getImage(nodes[1]) as IGatsbyImageData,
            title: 'MAC Strength MN',
            description:
                'As Dr. Alex’s first partnership program, Mac Strength helped us pave the trail to where we are today! Thank you to MAC!',
        },
        {
            image: getImage(nodes[2]) as IGatsbyImageData,
            title: 'American Strength Training Center',
            description:
                'We are honored to serve at this awesome power lifting gym, working alongside the state chairs of USAPL in MN.',
        },
        {
            image: getImage(nodes[3]) as IGatsbyImageData,
            title: 'HTP Sports Academy',
            description:
                'Very excited to announce our partnership with HTP Sports Academy located in Apple Valley! Go baseball!',
        },
        {
            image: getImage(nodes[4]) as IGatsbyImageData,
            title: 'IGM Gymnastics',
            description:
                'IGM offers recreational and competitive Rhythmic and Artistic gymnastics classes for boys and girls of all ages.',
        },
    ];

    return (
        <PartnersSection>
            <h5>Meet our partners</h5>
            <PartnersList>
                {partners.map((partner, i) => (
                    <Card key={i}>
                        <GatsbyImage image={partner.image} alt={partner.title} />
                        <h6>{partner.title}</h6>
                        <p>{partner.description}</p>
                    </Card>
                ))}
            </PartnersList>
        </PartnersSection>
    );
};
