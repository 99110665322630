import { Faq } from '@/Faq';
import { SingleQuestion } from '@/SingleQuestion';

export const HomeFAQ = () => {
    return (
        <Faq homepage>
            <SingleQuestion question="Do we accept insurance?">
                <p>We’re in network with all major insurance providers.</p>
            </SingleQuestion>
            <SingleQuestion question="What tools will I need?">
                <p>
                    Lacrosse ball and/or foam roller at first, then resistance bands as we progress!
                </p>
            </SingleQuestion>
            <SingleQuestion question="What should I wear during my visits?">
                <p>
                    Comfortable workout or loungewear clothing as we do quite a bit of muscle work
                    and will likely be getting on the floor!
                </p>
            </SingleQuestion>
            <SingleQuestion question="Is shockwave therapy covered under insurance?">
                <p>
                    No it is not, however the EPAT treatment has been recently FDA approved in the
                    US. We also offer discounted payment plans!
                </p>
            </SingleQuestion>
            <SingleQuestion question="Why do you have a gym?">
                <p>
                    We now offer personal training, strength and sport programming! Ask us about
                    pricing! We also have new machines that are great for physical therapy and
                    recovery training!
                </p>
            </SingleQuestion>
            <SingleQuestion question="What's new?">
                <p>
                    We now offer nutrition coaching, online and in person. Check out the
                    &ldquo;Wellness&rdquo; tab on the homepage for details! <br />
                    And we MOVED! New address in Burnsville: 1103 West Burnsville Pkwy Suite 120.
                </p>
            </SingleQuestion>
            <SingleQuestion question="Who do you treat?">
                <p>We love working with motivated people of all activity levels and goals.</p>
            </SingleQuestion>
        </Faq>
    );
};
