import { PrimaryLgBtn } from '@/Buttons/PrimaryLgBtn';
import { UnderlineBtn } from '@/Buttons/UnderLineBtn';
import { customScrollbar, h1, px } from '@/layout/GlobalStyles';
import care from '@a/homepage/care.svg';
import therapy from '@a/homepage/therapy.svg';
import training from '@a/homepage/training.svg';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

const OfferSection = styled.section`
    margin: 32px auto;
    padding: 32px 0;

    @media (min-width: 1280px) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 64px auto 0;
        padding: 48px 0 16px;
    }
`;

const OfferInfo = styled.div`
    ${px};
    margin-bottom: 16px;
    max-width: 470px;

    > h3 {
        ${h1};
        margin: 16px 0;
    }

    > p {
        :first-of-type {
            margin: 0;
            font-size: 1rem;
            font-weight: 700;
            color: ${({ theme }) => theme.green};
            letter-spacing: 0.1em;
        }

        :last-of-type {
            margin: 0;
        }
    }

    > a {
        :first-of-type {
            margin: 16px 0;
        }

        :last-of-type {
            display: block;
            margin: 0;
            color: ${({ theme }) => theme.green};
            font-weight: 500;

            :hover,
            :focus {
                color: ${({ theme }) => theme.darkGreen};
            }
        }
    }

    @media (min-width: 1280px) {
        max-width: 400px;
        padding: 0;
        margin-right: ${({ theme }) => theme.spacing.xl};
    }
`;

const Services = styled.div`
    ${customScrollbar};
    padding-bottom: 32px;

    > div {
        display: flex;
        align-items: flex-start;
    }

    @media (min-width: 1280px) {
        overflow-x: visible;

        > div {
            flex-direction: column;

            :first-of-type {
                //deliberate bigger margin then two rows
                margin-right: ${({ theme }) => theme.spacing.lg};
            }
        }
    }
`;

const OfferCard = styled('div', {
    shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{ $first?: boolean }>`
    width: 275px;
    flex-shrink: 0;
    padding: 16px;
    border: 1px solid rgba(83, 153, 63, 0.2);
    border-radius: 8px;
    background: none;
    transition: all 0.15s ease-in-out;
    transition-property: background, border, box-shadow;
    margin-right: 16px;
    margin-left: ${({ $first }) => ($first ? '16px' : 0)};

    :hover {
        box-shadow: ${({ theme }) => theme.boxShadow2};
        border: 1px solid transparent;
        background: ${({ theme }) => theme.white};
    }

    > h3 {
        margin: 16px 0;
        font-weight: 700;
        font-family: 'Raleway', sans-serif;
    }

    > p {
        margin: 0 0 16px;
    }

    @media (min-width: 768px) {
        margin-left: ${({ $first }) => ($first ? '32px' : 0)};
    }

    @media (min-width: 1280px) {
        margin-left: ${({ $first }) => ($first ? 0 : 0)};
        margin-right: 0;

        :first-of-type {
            margin-bottom: 64px;
        }
    }
`;

const offers = [
    {
        img: care,
        title: 'Sports Chiropractic',
        description:
            'Sports chiropractic specialize in diagnosing and treating sports/athletic performance related injuries. Their clinics provide injury prevention and rehabilitation care, and are experienced in creating treatment plans that allow athletes to return to their sport faster.',
        link: '/services/sports-chiropractic/',
    },
    {
        img: care,
        title: 'Shockwave Therapy',
        description:
            'We use Extracorporeal Pulse Activation Technology (EPAT®), which has a proven success rate that is equal to or greater than that of traditional treatment methods',
        link: '/services/shockwave-therapy/',
    },
    {
        img: therapy,
        title: 'Physical Therapy',
        description:
            'We offer one-on-one therapy sessions to address limitations in mobility, strength, and educate on proper movement patterns. ',
        link: '/services/physical-therapy/',
    },
    {
        img: training,
        title: 'Personal Training',
        description:
            'Our experienced team of personal trainers utilizes functional muscle testing paired with movement analysis to drive training programs.',
    },
];

export const Offer = () => {
    return (
        <OfferSection>
            <OfferInfo>
                <p>OUR SERVICES</p>
                <h3>What we offer</h3>
                <p>
                    Our local office has a wide variety of treatments to offer. Here are just a few
                    of the services that we provide:
                </p>
                <PrimaryLgBtn
                    as="a"
                    href="https://scheduling.go.promptemr.com/onlineScheduling?w=171&s=DL"
                    target="_blank"
                    rel="noreferrer"
                >
                    book an appointment
                </PrimaryLgBtn>
                <a href="#insurances">We accept most insurances.</a>
            </OfferInfo>

            <Services>
                <div>
                    {offers.slice(0, 2).map((offer, i) => (
                        <OfferCard key={i} $first={i === 0}>
                            <img src={offer.img} alt={offer.title} width={64} height={64} />
                            <h3>{offer.title}</h3>
                            <p>{offer.description}</p>
                            {offer.link ? (
                                <UnderlineBtn as={Link} to={offer.link}>
                                    Learn About {offer.title}
                                </UnderlineBtn>
                            ) : (
                                ''
                            )}
                        </OfferCard>
                    ))}
                </div>

                <div>
                    {offers.slice(2).map((offer, i) => (
                        <OfferCard key={i}>
                            <img src={offer.img} alt={offer.title} width={64} height={64} />
                            <h3>{offer.title}</h3>
                            <p>{offer.description}</p>
                            {offer.link ? (
                                <UnderlineBtn as={Link} to={offer.link}>
                                    Learn About {offer.title}
                                </UnderlineBtn>
                            ) : (
                                ''
                            )}
                        </OfferCard>
                    ))}
                </div>
            </Services>
        </OfferSection>
    );
};
