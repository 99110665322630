import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from 'gatsby-plugin-image';

const Doctors = styled.div<{ doctor?: boolean }>`
    display: flex;
    align-items: start;
    width: fit-content;
    margin: ${({ doctor }) => (doctor ? 0 : '0 auto')};
    padding: 0 3px;

    > .gatsby-image-wrapper {
        max-width: 300px;

        &:last-of-type {
            margin-top: 30%;
            margin-left: -17px;
            filter: drop-shadow(-8px -8px 32px rgba(0, 85, 80, 0.2));
        }
    }

    @media (min-width: 501px) {
        > .gatsby-image-wrapper:last-of-type {
            margin-left: -27px;
        }
    }

    @media (min-width: 1280px) {
        margin: 0;
    }
`;

export const TwoDoctors = ({ doctor }: { doctor?: boolean }) => {
    const data = useStaticQuery(graphql`
        query firsVisitImages {
            mobileAlex: file(relativePath: { eq: "homepage/first-visit/alex-mob.png" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            mobileMegan: file(relativePath: { eq: "homepage/first-visit/megan-mob.png" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            alex: file(relativePath: { eq: "homepage/first-visit/alex.png" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            megan: file(relativePath: { eq: "homepage/first-visit/megan.png" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
        }
    `);

    const alexImages = withArtDirection(getImage(data.alex) as IGatsbyImageData, [
        {
            media: '(max-width: 767px)',
            image: getImage(data.mobileAlex) as IGatsbyImageData,
        },
    ]);

    const meganImages = withArtDirection(getImage(data.megan) as IGatsbyImageData, [
        {
            media: '(max-width: 767px)',
            image: getImage(data.mobileMegan) as IGatsbyImageData,
        },
    ]);

    return (
        <Doctors doctor={doctor}>
            <GatsbyImage image={alexImages} alt="doctor alex" />
            <GatsbyImage image={meganImages} alt="doctor megan" />
        </Doctors>
    );
};
