import { h1 } from '@/layout/GlobalStyles';
import { TwoDoctors } from '@/TwoDoctors';
import styled from '@emotion/styled';

const StyledFirstVisit = styled.section`
    scroll-margin-top: 64px;

    @media (min-width: 1280px) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -64px;
        position: relative;
        z-index: 50;
    }
`;

const Expect = styled.div`
    padding: 0 13px;
    margin: -55px auto 0;
    max-width: 599px;

    > p {
        font-size: 1.125rem;
        line-height: 122%;
        color: ${({ theme }) => theme.green};
        font-weight: 600;
        font-family: 'Roboto', sans-serif;
        text-transform: uppercase;
        letter-spacing: -1.75px;
        margin: 0;
    }

    > h2 {
        ${h1};
        margin: 32px 0;
    }

    @media (max-width: 370px) {
        margin-top: -20px;
    }

    @media (min-width: 1280px) {
        margin: 0;
        margin-left: ${({ theme }) => theme.spacing.lg};
    }
`;

const Steps = styled.ol`
    list-style: none;
    counter-reset: first-visit-steps;
    padding-inline-start: 80px;
    margin: 0;

    > li {
        margin-bottom: 32px;
        font-weight: 700;
        font-size: 1.125rem;
        display: flex;
        align-items: center;

        ::before {
            counter-increment: first-visit-steps;
            content: counter(first-visit-steps);
            border: 1px solid ${({ theme }) => theme.green};
            border-radius: 50%;
            padding: 19.5px 25.75px;
            background: ${({ theme }) => theme.white};
            font-size: 1.16rem;
            font-weight: 500;
            line-height: 150%;
            color: ${({ theme }) => theme.green};
            position: relative;
            margin-left: -80px;
            margin-right: 16px;
            height: 64px;
            width: 64px;
        }
    }

    @media (min-width: 1280px) {
        padding-inline-start: 96px;
        position: relative;

        ::before {
            content: '';
            position: absolute;
            width: 1px;
            top: -8px;
            left: 32px;
            bottom: 64px;
            background: ${({ theme }) => theme.green};
        }

        > li::before {
            margin-right: 32px;
            margin-left: -96px;
        }
    }
`;

export const FirstVisit = () => {
    return (
        <StyledFirstVisit id="first-visit">
            <TwoDoctors />

            <Expect>
                <p>your first visit with us</p>
                <h2>Here&apos;s what to expect</h2>
                <Steps>
                    <li>Mirror Movement Assessment</li>
                    <li>Co-Evaluation: Dr Alex & Megan</li>
                    <li>Chiropractic</li>
                    <li>Progressive Rehab</li>
                </Steps>
            </Expect>
        </StyledFirstVisit>
    );
};
