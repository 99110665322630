import { h1, px } from '@/layout/GlobalStyles';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const DifferentSection = styled.section`
    ${px};
    padding-top: 32px;
    padding-bottom: 32px;

    > .gatsby-image-wrapper {
        border-top-left-radius: 128px;
        border-bottom-right-radius: 128px;
    }

    @media (min-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 64px;
        padding-bottom: 64px;
    }
`;

const TextBox = styled.div`
    > h5 {
        ${h1};
        margin: 32px 0;
        text-transform: capitalize;
    }

    > ul {
        margin: 0;
    }

    @media (min-width: 1024px) {
        max-width: 550px;
        margin-left: ${({ theme }) => theme.spacing.xxl};

        > h5 {
            margin-top: 0;
        }
    }
`;

export const Different = () => {
    return (
        <DifferentSection>
            <StaticImage
                src="../../../assets/images/homepage/different.jpg"
                alt="integrate institute"
                width={400}
                height={600}
            />
            <TextBox>
                <h5>What Makes Us Different</h5>
                <ul>
                    <li>
                        Dr. Alex Twenge is Doctor of Chiropractic and Megan Twenge has a Masters in
                        Occupational Therapy
                    </li>
                    <li>Only partner with the USAPL (USA Powerlifting) state chairs in MN.</li>
                    <li>
                        Dr. Alex has been doing pitching development and programming for over 6
                        years.
                    </li>
                    <li>Each visit includes Chiro & rehab session </li>
                    <li>Offering lots of soft tissue muscle work </li>
                    <li>Customized exercises & progression </li>
                    <li>Movement & recovery specialists</li>
                    <li>
                        Experts in overhead athletes (shoulders) & jumping athletes ie: baseball,
                        softball, VB, basketball, weight lifters
                    </li>
                </ul>
            </TextBox>
        </DifferentSection>
    );
};
