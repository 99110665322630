import styled from '@emotion/styled';
import { PlainBtn } from './PlainBtn';

export const UnderlineBtn = styled(PlainBtn)`
    color: ${({ theme }) => theme.green};
    font-size: 0.875rem;
    font-weight: 500;
    padding-bottom: 3px;
    border-bottom: 4px solid ${({ theme }) => theme.green};
    margin-bottom: 0;
    transition: all 0.3s ease-in-out;
    transition-property: border-bottom, color, margin-bottom;

    :hover,
    :focus {
        margin-bottom: 3px;
        border-bottom: 1px solid transparent;
        color: ${({ theme }) => theme.darkGreen};
    }
`;
